



































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import ImageUpload from '@/components/ImageUpload.vue'
import * as RecipeAPI from '@/api/recipe'
import { DescriptionStep, IngredientEntry } from '@/api/recipe'
import router from '@/router'
/* eslint-disable @typescript-eslint/camelcase */

interface Tag {
  id: number
  name: string
  language_id: number
}
interface TimeDisplay {
  days: number
  hours: number
  minutes: number
}
@Component({
  name: 'Create',
  components: { ImageUpload }
})
export default class extends Vue {
  preview = null
  file = null
  image = null
  loading = false
  timeMenu = null
  timeDisplay: TimeDisplay = {
    days: 0,
    hours: 0,
    minutes: 0
  }
  get requiredTimeDisplay() {
    let output = 'Zeit: '
    if (this.timeDisplay.days != 0) {
      output += this.timeDisplay.days.toString() + 'd '
    }
    if (this.timeDisplay.hours != 0) {
      output += this.timeDisplay.hours.toString() + 'h '
    }
    if (this.timeDisplay.minutes != 0) {
      output += this.timeDisplay.minutes.toString() + 'm'
    }
    if (output == 'Zeit: ') {
      output = 'Benötigte Zeit'
    }
    return output
  }
  portions = 2

  valid = true
  name = ''
  nameRules = [v => !!v || 'Name is required', v => (v && v.length <= 90) || 'Name must be less than 90 characters']
  email = ''
  emailRules = [v => !!v || 'E-mail is required', v => /.+@.+\..+/.test(v) || 'E-mail must be valid']
  select = null
  items = ['Item 1', 'Item 2', 'Item 3', 'Item 4']
  meat = false
  vegetarian = false
  vegan = false
  publicViewable = true
  anonymous = false
  tagSearch = null
  tags: Tag[] = []

  newIngredient: IngredientEntry = {
    ingredient: { id: -1, name: [{ language_id: 2, translation: '' }] },
    amount: null,
    amount_suffix: null
  }
  @Watch('ingredients', { immediate: true, deep: true })
  onNewIngredientChanged(ingredients: IngredientEntry[]) {
    for (let index = 0; index < ingredients.length; index++) {
      const ingredient = ingredients[index]
      if (
        (ingredient.ingredient.name[0].translation == null || ingredient.ingredient.name[0].translation == '') &&
        (ingredient.amount == null || ingredient.amount == 0) &&
        (ingredient.amount_suffix == null || ingredient.amount_suffix == '')
      ) {
        this.ingredients.splice(index, 1)
      }
    }
  }
  removeIngredient(index: number) {
    this.ingredients.splice(index, 1)
  }

  @Watch('descriptionSteps', { immediate: true, deep: true })
  ondescStepsChanged(descriptionSteps: DescriptionStep[]) {
    for (let index = 0; index < descriptionSteps.length; index++) {
      const step = descriptionSteps[index]
      if (step.description[0].translation == null || step.description[0].translation == '') {
        this.descriptionSteps.splice(index, 1)
      }
    }
  }
  ingredients: IngredientEntry[] = []
  descriptionSteps: DescriptionStep[] = []
  newDescription = ''
  newIngredientCounter = 1
  checkIfCreateNeeded() {
    if (
      this.newIngredient.ingredient.name[0].translation != null &&
      this.newIngredient.ingredient.name[0].translation != ''
    ) {
      this.ingredients.push(this.newIngredient)
      this.newIngredient = {
        ingredient: { id: -this.newIngredientCounter, name: [{ language_id: 2, translation: '' }] },
        amount: null,
        amount_suffix: null
      }
      this.newIngredientCounter++
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      //@ts-ignore
      this.$refs.newFocus.focus()
    }
  }

  /**
   * Upload to database
   */
  async create() {
    this.loading = true
    const formData = new FormData()
    let imageFile: any
    //image
    if (this.preview != null) {
      imageFile = {
        file: this.file,
        name: this.file.name
      }
      formData.append('image', imageFile.file, imageFile.name)
    }
    formData.append(
      'recipeData',
      JSON.stringify({
        required_time: this.timeDisplay.days * 24 * 60 + this.timeDisplay.hours * 60 + this.timeDisplay.minutes,
        meat: this.meat,
        vegetarian: this.vegetarian,
        vegan: this.vegan,
        portions: this.portions,
        ingredients: this.ingredients,
        name: this.name,
        descriptionSteps: this.descriptionSteps,
        image: this.file,
        public: this.publicViewable,
        anonymous: this.anonymous,
        tags: this.tags
      })
    )
    const resp = await RecipeAPI.create(formData)
    this.loading = false
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    //@ts-ignore
    if (resp.code == 20000) {
      router.push({ path: '/' })
    }
  }
  createStep() {
    if (this.newDescription != '') {
      this.descriptionSteps.push({
        description: [{ translation: this.newDescription, language_id: 2 }]
      })
      this.newDescription = ''
    }
  }
  validate() {
    ;(this.$refs.form as any).validate()
  }
  reset() {
    ;(this.$refs.form as any).reset()
  }
  resetValidation() {
    ;(this.$refs.form as any).resetValidation()
  }
}
