



















import { Component, Vue, Watch, PropSync, Prop } from 'vue-property-decorator'

@Component({
  name: 'ImageUpload',
  components: {}
})
export default class extends Vue {
  @PropSync('preview', { default: null, type: String }) previewPS: string
  @PropSync('previewData', { default: null, type: File }) previewDataPS!: File
  @Prop({ default: null, type: Number }) imgMaxWidth!: number
  @PropSync('imagesource', { default: null, type: String }) serverImage!: string
  isDragging = false
  wrongFile = false
  get getClasses() {
    return { drop: true, isDragging: this.isDragging }
  }
  dragOver() {
    this.isDragging = true
  }
  dragLeave() {
    this.isDragging = false
  }
  drop(e) {
    const files = e.dataTransfer.files

    this.wrongFile = false
    console.log(typeof this.previewPS)
    // allows only 1 file
    if (files.length === 1) {
      const file = files[0]

      // allows image only
      if (file.type.indexOf('image/') >= 0) {
        const reader = new FileReader()
        reader.onload = (f: any) => {
          this.previewPS = f.target.result as string
          console.log(this.previewPS)

          this.previewDataPS = file
          console.log(this.previewDataPS)
          console.log(file)
          this.isDragging = false
        }
        reader.readAsDataURL(file)
      } else {
        this.wrongFile = true
        this.previewDataPS = null
        this.previewPS = null
        this.isDragging = false
      }
    }
  }
  onRequestUploadFiles() {
    console.log('requesting upload')
  }
}
